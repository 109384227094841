import httpHelper from "@/helpers/httpHelper";

const gameService = {
    async detail(aliasUrl) {
        return await httpHelper.get("/WebApi/Game/Detail", {aliasUrl});
    },
    async getAll() {
        return await httpHelper.get("/WebApi/Game/GetAll");
    }
};

export default gameService;