<template>
  <div class="page page-game-detail">
    <b-col class="py-3">
      <GameHeader>
        <template #breadcrumb>
          <Breadcrumb :items="[{label: $t('Giftcode'), click: () => $router.push({name: 'GiftCodeIndex'})}, {label: $t('Game detail')}]"/>
        </template>
      </GameHeader>
      <RowLine />
      <ButtonsTab :tabs="tabs" />
      <router-view/>
    </b-col>
  </div>
</template>

<script>
import ButtonsTab from "@/components/tabs/ButtonsTab";
import GameHeader from "@/components/headers/GameHeader";
import {redirectModes} from "@/contants";

export default {
  name: "Template",
  components: {ButtonsTab, GameHeader},
  data() {
    return {
      tabs: [{
        label: "All",
        mode: redirectModes.ROUTE_NAME,
        value: "GameGiftCodeIndex"
      }, {
        label: "Received",
        mode: redirectModes.ROUTE_NAME,
        value: "GameGiftCodeReceived"
      }, {
        label: "Use code",
        mode: redirectModes.ROUTE_NAME,
        value: "GameGiftCodeUseCode"
      }]
    }
  },
  async created() {
    this.$setPageTitle(this.$t("Receive & use giftcode"));
  }
}
</script>