<template>
  <div class="game-header">
    <LoadingPanel v-if="loading"/>
    <NotFoundPanel v-else-if="game == null"/>
    <div v-else>
      <slot name="breadcrumb" v-bind="game">
        <div v-if="game">
          <Breadcrumb v-if="pageName" :items="[{label: $t('Game detail'), click: () => $router.push({name: 'GameDetail', params: {aliasUrl: game.aliasUrl}})}, {label: pageName}]"/>
          <Breadcrumb v-else :items="[{label: game.name}]"/>
        </div>
      </slot>
      <div class="d-flex">
        <img :src="game.iconUrl.getUrl()" class="game-icon mr-3" />
        <div class="game-content">
          <h1 class="game-name">{{game.name}}</h1>
          <div class="game-links d-flex justify-content-between">
            <div class="game-link" v-if="game.downloadUrl && game.downloadUrl.length > 0">
              <a :href="game.downloadUrl" download>
                <img src="../../assets/images/icon/game-toolbar/download.svg" class="mr-1" />{{$t("Download game")}}
              </a>
            </div>
            <div class="game-link" v-if="game.homeUrl && game.homeUrl.length > 0">
              <a :href="game.homeUrl">
                <img src="../../assets/images/icon/game-toolbar/homepage-grey.svg" class="mr-1" />{{$t("Home page")}}
              </a>
            </div>
            <div class="game-link" v-if="game.fanpageUrl && game.fanpageUrl.length > 0">
              <a :href="game.fanpageUrl">
                <img src="../../assets/images/icon/game-toolbar/fanpage-gray.svg" class="mr-1" />{{$t("Fanpage")}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gameService from "@/services/gameService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "GameHeader",
  props: ["aliasUrl", "pageName"],
  data() {
    return {
      game: null,
      loading: true
    }
  },
  async created() {
    let game;
    let aliasUrl = this.aliasUrl || this.$route.params.aliasUrl;
    if (!aliasUrl) {
      this.loading = false;
      return;
    }

    try {
      const json = window.sessionStorage.getItem("game:" + aliasUrl);
      if (json)
        game = JSON.parse(json);
    } catch (e) {
      console.log(e);
    }

    if (game) {
      this.game = game;
      await this.$store.dispatch("setCurrentGame", game);
      this.$bridge.$emit("setCurrentGame", game);
      this.loading = false;
      return;
    }

    const response = await gameService.detail(aliasUrl);
    if (!response) {
      this.loading = false;
      await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      return;
    }

    game = response.data;
    await this.$store.dispatch("setCurrentGame", game);
    try {
      window.sessionStorage.setItem("game:" + aliasUrl, JSON.stringify(game));
    } catch (e) {
      console.log(e);
    }

    this.game = game;
    this.loading = false;
    this.$bridge.$emit("setCurrentGame", game);
  }
}
</script>

<style scoped lang="scss">
.game-header {
  .game-icon {
    height: 52px;
    width: 52px;
    object-fit: contain;
  }

  .game-content {
    flex: 1;

    .game-name {
      font-size: 16px;
      font-weight: 400;
    }

    .game-links {
      .game-link {
        font-size: 12px;
        padding-right: 5px;

        a {
          color: #8e8e93;

          img {
            vertical-align: text-top;
          }
        }
      }
    }
  }
}
</style>
