<template>
  <b-row class="button-tabs">
    <b-col>
      <div class="list-tabs d-flex justify-content-between">
        <div class="button" v-for="(tab, index) in tabs" :key="index">
          <b-button :variant="currentRoute == tab.value ? 'primary' : 'outline-secondary'" block class="btn-rounded" @click="$redirectTo(tab)">
            {{ $t(tab.label) }}
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ButtonsTab",
  props: ["tabs"],
  data() {
    return {
      currentRoute: ""
    }
  },
  created() {
    this.setCurrentRoute();
  },
  methods: {
    setCurrentRoute() {
      this.currentRoute = this.$route.name;
    }
  },
  watch: {
    "$route.name": function () {
      this.setCurrentRoute();
    }
  }
}
</script>

<style scoped lang="scss">
.button-tabs {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 15px;

  .list-tabs {
    margin: 0 -5px;

    .button {
      flex: 1;
      padding: 5px 5px 15px 5px;
      
      .btn-outline-secondary {
        border: 1px solid rgb(118, 118, 118) !important;
        color: rgb(118, 118, 118) !important;
        
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>